import { Image } from 'components/Image'
import { useEffect, useState, useContext, useMemo } from 'react'
import { debounce as _debounce } from 'lodash-es'
import useMobileNavState from 'hooks/useMobileNavState'
import useIsSsr from 'hooks/useIsSsr'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { Box, Button, Typography, AchieveTheme } from '@achieve/ascend'
import Container from '@mui/material/Container'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { SignInLink } from 'components/SignInLink'
import Navigation from 'components/Navigation'
import HeaderMobileActions from './HeaderMobileActions'
import HeaderCta from './HeaderCta'
import styles from './Header.module.scss'
import { handleTrackAndReactEvent } from 'utils/analytics'
import useLayoutMenuCondensed from 'hooks/useLayoutMenuCondensed'
import { LayoutContext } from 'providers/LayoutProvider'
import { X as CloseIcon, Plus } from 'react-feather'
import { useTriggerEvents } from 'hooks/useTriggerEvents'
import { PhoneDataContext } from 'providers/PhoneDataProvider'
import { MediaImageStandard } from 'components/Contentful'
import Head from 'next/head'
import AchieveLogoSVG from 'components/Svgs/AchieveLogoSVG.js'
import MenuIcon from 'components/Svgs/Menu.js'
import Close from 'components/Svgs/Close.js'
// Percentage of the window that needs to scroll for the mobile header to condense
const MOBILE_CONDENSED_THRESHOLD = 0.3

/**
 * Main site Header component
 * @param {{
 *  mainNavigation: object
 *  disabledRoutes: []
 * }} props
 */
export default function Header({
  disabledElements,
  disabledRoutes,
  mainNavigation,
  logoEvent,
  signInLinkUrl,
  signInLinkText,
  signInEvent,
  linkUrl,
  linkText,
  headerCta = true,
  linkEvent,
  showNav = true,
  showSignIn = true,
  customPhoneData = null,
  partnerLogo,
  logoLink = true,
  showPhone = true,
  showProgressPage = false,
}) {
  const [, setLayoutMenuCondensed] = useLayoutMenuCondensed()
  const { fireAndForget } = useTriggerEvents()
  const [mobileNavOpen, setMobileNavOpen] = useMobileNavState()
  const [condensed, setCondensed] = useState(false)
  const [mobileCondensed, setMobileCondensed] = useState(false)
  const isSsr = useIsSsr()
  const mqHookResults = useViewportSmallerThan(BREAKPOINTS.lg)
  const [buttonCall, setButtonCall] = useState(false)
  let phoneData = useContext(PhoneDataContext)
  const { state: { isMobileUA } = {} } = useContext(LayoutContext)
  const phoneIconUrl = '/next-assets/icon-phone.svg'
  const [progressPage, setProgressPage] = useState(0)

  /**
   * The below logic is used specifically for home-equity-loans-mp. AHL Direct Mail campaign
   * requires phone number to be based on utm_source and utm_content. Phone number data is
   * fetched from pageConfig object in Home Equity Loan MP contentful page entry.
   * Implemented by PXP team.
   * Contact: Shreyas Dorle, Brandon Chapman
   */
  if (customPhoneData) {
    const { utm_source, utm_content, ahl_dm_creatives } = customPhoneData ?? {}
    const {
      informed_delivery,
      digital,
      default_values,
      utm_source: ahl_dm_utm_source,
    } = ahl_dm_creatives ?? {}
    if (utm_source === ahl_dm_utm_source) {
      if (utm_content?.toUpperCase() === informed_delivery?.utm_content) {
        phoneData.phoneNumber = informed_delivery?.phone
      } else if (utm_content?.toUpperCase() === digital?.utm_content) {
        phoneData.phoneNumber = digital?.phone
      } else {
        phoneData.phoneNumber = default_values?.phone
      }
    }
  }

  // Default to mobile first before initial render if the user agent matches a mobile device
  const isMobile = isSsr ? isMobileUA : mqHookResults

  const debounceOptions = {
    leading: true,
    trailing: false,
  }

  const setMobileCondensedTrue = _debounce(() => setMobileCondensed(true), 100, debounceOptions)
  const setMobileCondensedFalse = _debounce(() => setMobileCondensed(false), 100, debounceOptions)
  const setCondensedTrue = _debounce(() => setCondensed(true), 100, debounceOptions)
  const setCondensedFalse = _debounce(() => setCondensed(false), 100, debounceOptions)

  /**
   * Set the initial condensed state based on window scroll position
   */
  useEffect(() => {
    if (isSsr) {
      // no window to measure during SSR
      return
    }

    onScroll()
    // This effect is only intended to run on the first browser (not SSR) render
  }, [isSsr]) /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    const handleScroll = _debounce(
      () => {
        onScroll()
      },
      30
    )
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  function onScroll() {
    const currentScroll = window.scrollY
    const scrollHeight = document.documentElement.scrollHeight - window.innerHeight
    const scrollPercentage = (currentScroll / scrollHeight) * 100
    setProgressPage(scrollPercentage)

    if (isMobile) {
      return handleMobileScroll()
    }
    return handleDesktopScroll()
  }

  /**
   * Enable the condensed mobile header when the page is scrolled passed the threshold
   */
  function handleMobileScroll() {
    const currentScroll = window.scrollY
    const nextMobileCondensed = currentScroll > window.innerHeight * MOBILE_CONDENSED_THRESHOLD

    if (nextMobileCondensed === mobileCondensed) {
      return
    }

    if (nextMobileCondensed) {
      return setMobileCondensedTrue()
    }

    return setMobileCondensedFalse()
  }

  /**
   * Enable the condensed desktop header when the page is scrolled any distance from the top
   */
  function handleDesktopScroll() {
    const currentScroll = window.scrollY
    const nextCondensed = Boolean(currentScroll)

    if (nextCondensed === condensed) {
      return
    }

    if (nextCondensed) {
      return setCondensedTrue()
    }

    return setCondensedFalse()
  }

  /*
    Prevent body scroll when the mobile navigation menu is open
  */
  useEffect(() => {
    const body = document.querySelector('body')

    if (!body) {
      return
    }

    if (isMobile && mobileNavOpen) {
      // Prevent the body scrolling under the open mobile nav menu by fixing the body element,
      // setting the height to the viewport height and setting the overflow to hidden
      body.style.position = 'fixed'
      body.style.height = '100vh'
      body.style.width = '100vw'
      body.style.overflow = 'hidden'
      return
    }

    // Reset the body css to scroll and overflow normally.
    body.style.position = 'initial'
    body.style.height = 'initial'
    body.style.width = 'initial'
    body.style.overflow = 'initial'
  }, [isMobile, mobileNavOpen])

  const handleCloseClick = (event, track) => {
    fireAndForget({
      event_type: track?.event_type,
      ...handleTrackAndReactEvent(event, track),
    })
    !isSsr && window.scrollTo({ top: 0, behavior: 'instant' })
    setMobileNavOpen(false)
  }

  const handleOpenClick = (event, track) => {
    fireAndForget({
      event_type: track?.event_type,
      ...handleTrackAndReactEvent(event, track),
    })
    setMobileNavOpen(true)
  }

  useEffect(() => {
    setLayoutMenuCondensed(isMobile ? mobileCondensed : condensed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, condensed, mobileCondensed])

  const callToAssociatedNumber = () => {
    if (phoneData?.phoneNumber) window.open(`tel:${phoneData.phoneNumber}`, '_self')
  }

  const handleCall = () => {
    if (isMobile) callToAssociatedNumber()
    else setButtonCall(!buttonCall)
  }

  const getSubNav = () => {
    if (!isMobile || !phoneData?.phoneNumber) return ''
    return (
      <Box className={styles['header-subnav']}>
        <Typography variant="bodyLg" fontWeight="regular" data-testid="header-subnav-phone-copy">
          {phoneData?.phoneCopy}
        </Typography>
        <AchieveLink
          className={styles['title-link']}
          noLink
          track={{
            list_name: 'PHONE CALL CLICK HEADER',
            click_id: phoneData?.phoneNumber,
            click_text: `Achieve-Web | ${phoneData?.phoneNumber}`,
            click_url: phoneData?.phoneNumber,
            click_type: 'Phone Click',
            event_action: 'button_click',
            nav_link_section: 'Header',
            track_event: 'global_header',
          }}
        >
          <Button
            variant="outlined"
            size="small"
            startIcon={getMobileIcon()}
            onClick={callToAssociatedNumber}
            data-testid="header-subnav-phone-button"
          >
            {phoneData?.phoneNumber}
          </Button>
        </AchieveLink>
      </Box>
    )
  }

  const getMobileIcon = () => {
    return (
      <Box position={'relative'}>
        <MediaImageStandard
          className={styles['phone-icon']}
          alt={'phone icon'}
          height={condensed ? 20 : 24}
          content={{ url: phoneIconUrl }}
          width={condensed ? 20 : 24}
          layout="fill"
          onClick={handleCall}
        />
      </Box>
    )
  }

  const AchieveLogo = ({ mobile, logoLink }) => {
    /*Combination as follows:
      Mobile with Link
      Mobile without Link
      Desktop with Link
      Desktop without Link
    */
    return mobile ? (
      logoLink ? (
        <AchieveLink
          track={{
            list_name: 'Header Logo',
            click_id: '2022_Achieve_Logo_RGB',
            click_text: 'Achieve-Web | Header logo',
            nav_link_section: 'Header logo',
            track_event: 'global_header',
            event_action: 'link_click',
            event_type: 'link_click',
          }}
          href="/"
          withNextLink
          className={styles['mobile-logo']}
          data-testid="achieve-header-mobile-logo"
          onClick={() => setMobileNavOpen(false)}
        >
          <AchieveLogoSVG title="Achieve Mobile Logo" className={styles['logo-svg']} />
        </AchieveLink>
      ) : (
        <div className={styles['mobile-logo']}>
          <AchieveLogoSVG title="Achieve Mobile Logo" />
        </div>
      )
    ) : logoLink ? (
      <AchieveLink
        track={{
          ...{
            list_name: 'Header Logo',
            click_id: '2022_Achieve_Logo_RGB',
            click_text: 'Achieve-Web | Header logo',
            nav_link_section: 'Header logo',
            track_event: 'global_header',
            event_action: 'link_click',
            event_type: 'link_click',
          },
          ...logoEvent,
        }}
        href="/"
        withNextLink
        className={styles['logo']}
        data-testid="achieve-header-logo"
      >
        <AchieveLogoSVG title="Achieve Logo" />
      </AchieveLink>
    ) : (
      <div className={styles['logo']}>
        <AchieveLogoSVG title="Achieve Logo" />
      </div>
    )
  }

  const PartnerLogo = ({ src }) => {
    return (
      <div className={styles['partner-logo']}>
        <Image
          src={src}
          alt="Achieve partner logo"
          className={styles['logo-image']}
          height="60"
          width="180"
          priority={true}
        />
      </div>
    )
  }

  const AchieveLogoMobile = useMemo(
    () => <AchieveLogo mobile={true} logoLink={logoLink} />,
    [logoLink]
  )

  const AchieveLogoDesktop = useMemo(
    () => <AchieveLogo mobile={false} logoLink={logoLink} />,
    [logoLink]
  )

  return (
    <>
      <header
        className={styles.header}
        data-condensed={condensed}
        data-mobile-condensed={mobileCondensed}
        data-mobile-nav-open={mobileNavOpen}
        data-narrow={true}
        data-testid="acx-website-header"
      >
        {showPhone && (
          <Head>
            <link rel="preload" href={phoneIconUrl} as="image" type="image/svg+xml" />
          </Head>
        )}
        <Container
          maxWidth={false}
          disableGutters
          className={styles['header-content-container']}
          data-narrow={true}
        >
          <div className={styles['header-content']}>
            {/* Container for content shown in the mobile header bar */}
            <div className={styles['mobile-header']}>
              {partnerLogo ? (
                <div className={styles['logo-flex']}>
                  {AchieveLogoDesktop}
                  <div className={styles['logo-container']}>
                    <Plus height={30} width={30} />
                  </div>
                  <PartnerLogo src={partnerLogo?.file?.url} />
                </div>
              ) : (
                AchieveLogoDesktop
              )}
              {AchieveLogoMobile}
              {/* Mobile nav menu open and close buttons */}
              <div className={styles['mobile-menu-controls']}>
                {isMobile && phoneData?.phoneNumber && (
                  <Box
                    className={styles['call-cta']}
                    data-call-cta-mobile={true}
                    data-open-button-call={buttonCall}
                  >
                    <AchieveLink
                      className={styles['call-cta-link']}
                      noLink
                      track={{
                        list_name: 'PHONE CALL CLICK HEADER',
                        click_id: phoneData?.phoneNumber,
                        click_text: `Achieve-Web | ${phoneData?.phoneNumber}`,
                        click_url: phoneData?.phoneNumber,
                        click_type: 'Phone Click',
                        event_action: 'button_click',
                        nav_link_section: 'Header',
                        track_event: 'global_header',
                      }}
                      data-testid="mobile-phone-number-link"
                    >
                      {getMobileIcon()}
                    </AchieveLink>
                  </Box>
                )}
                {showNav && (
                  <>
                    <button
                      onClick={(e) => {
                        handleOpenClick(e, {
                          nav_link_section: 'Toggle menu',
                          click_type: 'Button Click',
                          click_id: 'open button',
                        })
                      }}
                      className={styles['mobile-menu-button']}
                      data-testid="mobile-nav-open-button"
                      aria-label="Main navigation menu button"
                    >
                      <MenuIcon />
                    </button>
                    <button
                      onClick={(e) => {
                        handleCloseClick(e, {
                          nav_link_section: 'Toggle menu',
                          click_type: 'Button Click',
                          click_id: 'close button',
                        })
                      }}
                      role="button"
                      className={styles['mobile-close-button']}
                      data-testid="mobile-nav-close-button"
                      aria-label="Main navigation menu close button"
                    >
                      <Typography variant="bodyXs" fontWeight="medium" component="span">
                        CLOSE
                      </Typography>
                      <Close title="Close button" />
                    </button>
                  </>
                )}
              </div>
            </div>

            {/* Main nav and sub nav list components */}
            {showNav && (
              <Navigation
                event={linkEvent}
                condensed={condensed}
                mobileNavOpen={mobileNavOpen}
                items={mainNavigation}
                disabledRoutes={disabledRoutes}
                disabledElements={disabledElements}
                mobileSubnav={getSubNav()}
              />
            )}
            {/* Right Panel */}
            <div
              className={styles['header-right-section']}
              data-open-button-call={buttonCall}
              data-none-phone-number={phoneData?.phoneNumber ? false : true}
            >
              {phoneData?.phoneNumber && !isMobile && showPhone && (
                <Box className={styles['call-cta']}>
                  <Box className={styles['call-cta-container']}>
                    {getMobileIcon()}
                    <Box className={styles['button-call-link']}>
                      <AchieveLink
                        noLink
                        track={{
                          list_name: 'PHONE CALL CLICK HEADER',
                          click_id: phoneData?.phoneNumber,
                          click_text: `Achieve-Web | ${phoneData?.phoneNumber}`,
                          click_url: phoneData?.phoneNumber,
                          click_type: 'Phone Click',
                          event_action: 'button_click',
                          nav_link_section: 'Header',
                          track_event: 'global_header',
                        }}
                        data-testid="desktop-phone-number-link"
                      >
                        <Typography
                          variant="bodySm"
                          fontWeight="regular"
                          onClick={() => callToAssociatedNumber()}
                          data-testid="desktop-phone-number"
                        >
                          {phoneData?.phoneNumber}
                        </Typography>
                      </AchieveLink>
                    </Box>
                    <CloseIcon
                      className={styles['icon-close-link']}
                      width={18}
                      height={18}
                      color={AchieveTheme?.ascend?.colors?.neutral?.[150]}
                      onClick={handleCall}
                    />
                  </Box>
                </Box>
              )}
              {/* "Get Started" CTA button is always present in desktop and is hidden and shown on
                scroll in mobile. */}
              {headerCta && (
                <HeaderCta
                  linkUrl={linkUrl}
                  linkText={linkText}
                  size="small"
                  data-condensed={isMobile || condensed}
                  className={styles['header-cta']}
                  data-testid="header-get-started-button"
                />
              )}
              {showSignIn && (
                <SignInLink
                  signInLinkUrl={signInLinkUrl}
                  signInLinkText={signInLinkText}
                  event={signInEvent}
                  className={styles['sign-in-link']}
                  data-testid="header-sign-in-link"
                  disabledRoutes={disabledRoutes}
                  underline="hover"
                />
              )}
            </div>
            {/* Action bar that shows at the bottom of the mobile nav */}
            {isMobile && (
              <HeaderMobileActions disabledRoutes={disabledRoutes} headerCta={headerCta} />
            )}
          </div>
        </Container>
        {condensed && showProgressPage && (
          <div className={styles['progress-page']}>
            <div className={styles['progress']} style={{ width: `${progressPage}%` }} />
          </div>
        )}
      </header>
    </>
  )
}
